<template>
  <div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h4>
                     تسجيل ساعات التطوع <button v-b-modal.add class="btn btn-sm btn-primary" >
                        <i class="fa fa-plus"></i>
                        اضافة جديد
                    </button>
                </h4>
                <div class="gs">
                    <button class="btn btn-sm btn-success" v-b-modal.print style="border-radius: 0px 5px 5px 0px">
                        <i class="fa fa-print"></i>
                        خيارات الطباعة
                    </button>
                    <button class="btn btn-sm btn-danger" v-b-modal.filter style="border-radius: 5px 0px 0px 5px">
                        <i class="fa fa-filter"></i>
                        فلترة النتائج
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-sm table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>
                                    الطالب
                                </th>
                                <th>
                                    المجال العام للتطوع
                                </th>
                                <th>
                                    تفاصيل التطوع
                                </th>
                                <th>
                                    عدد الساعات	
                                </th>
                                <th>
                                    تاريخ الاضافة
                                </th>
                                <th>
                                    خيارات
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item._id">
                                <td>
                                    {{ getStudent(item.number).name }}
                                </td>
                                <td>
                                    {{ item.type }}
                                </td>
                                <td>
                                    {{ item.details }}
                                </td>
                                <td>
                                    {{ item.hours }}
                                </td>
                                <td>
                                    {{ item.date }}
                                </td>
                                <td class="hidemonprint">
                                    <b-dropdown dropleft style="width: 100%"
                                        id="dropdown-1"
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        text="خيارات"
                                        variant="secondary"
                                        size="sm"
                                    >
                                        <b-dropdown-item @click="deleteItem(item._id)">
                                            <i class="fa fa-trash"></i>
                                            حذف
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="add" hide-footer size="sm">
        اختر الطالب:
        <v-select :options="students" dir="rtl" v-model="selectedStudent" placeholer="اختر الطالب..." label="name">
        </v-select>
        <br>
        <div class="form-group">
        <label for="">المجال العام للتطوع</label>
          <select class="form-control" v-model="type">
            <option value="">-- اختر --</option>
            <option v-for="t in types" :key="t">{{ t }}</option>
          </select>
        </div>
        <div class="form-group">
            <label for="">تفاصيل التطوع</label>
            <textarea class="form-control" v-model="details" id="" placeholder="اكتب هنا..." rows="3"></textarea>
        </div>
        <div class="form-group">
            <label for="">ساعات التطوع</label>
            <input type="number"
            class="form-control" v-model="hours" required>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="addBreak()">
                اضافة
                <i class="fa fa-arrow-left"></i>
            </button>
        </div>
    </b-modal>
    <b-modal id="filter" hide-footer size="sm">
        اختر الطالب: (اتركه فارغاً لتحديد الكل)
        <v-select :options="students" dir="rtl" v-model="student_number" placeholer="اختر الطالب..." label="name">
        </v-select>
        <br>
        <div class="form-group">
          <label for="">التاريخ من</label>
          <input type="date"
            class="form-control" v-model="from">
        </div>
        <div class="form-group">
          <label for="">التاريخ الى</label>
          <input type="date"
            class="form-control" v-model="to">
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="closeFilter();getItems()">
                <i class="fa fa-search"></i>
                بحث
            </button>
        </div>
    </b-modal>
    <b-modal id="print" hide-footer size="sm">
        <div class="col-12 g">
            <button class="btn btn-success btn-block" @click="print(arr)">
                <i class="fa fa-print"></i>
                طباعة تقرير كل الطلاب
            </button>
        </div>
        <div class="col-12 g">
            <button class="btn btn-primary btn-block" @click="customprint('have')">
                <i class="fa fa-print"></i>
                طباعة من لهم ساعات
            </button>
        </div>
        <div class="col-12 g">
            <button class="btn btn-danger btn-block" @click="customprint('nothave')">
                <i class="fa fa-print"></i>
                طباعة من ليس لهم ساعات
            </button>
        </div>
        <div class="col-12 g">
           <div class="row">
            <div class="col-10">
                 تقرير طالب معين:
                <v-select :options="students" dir="rtl" v-model="student_" placeholer="اختر الطالب..." label="name">
                </v-select>
            </div>
            <div class="col-2">
                <br>
                <button class="btn btn-primary btn-sm" style="position: relative; right: -20px" @click="customprint('student', student_.number)">عرض</button>
            </div>
           </div>
        </div>
        <div class="col-12 g">
           <div class="row">
            <div class="col-10">
                 تقرير صف معين:
                <v-select :options="classnames" dir="rtl" v-model="custom" placeholer="اختر الطالب..." label="title">
                </v-select>
            </div>
            <div class="col-2">
                <br>
                <button class="btn btn-primary btn-sm" style="position: relative; right: -20px" @click="customprint('classname', custom.title)">عرض</button>
            </div>
           </div>
        </div>
        <div class="col-12 g">
           <div class="row">
            <div class="col-10">
                 تقرير فصل معين:
                <v-select :options="classrooms" dir="rtl" v-model="custom2" placeholer="اختر الطالب..." label="title">
                </v-select>
            </div>
            <div class="col-2">
                <br>
                <button class="btn btn-primary btn-sm" style="position: relative; right: -20px" @click="customprint('classroom', custom2.title)">عرض</button>
            </div>
           </div>
        </div>
        <br><br><br><br><br><br><br>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BFormInput, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
    components: {
        // Layouts
        BDropdown, BDropdownItem,
        vSelect,
        BModal,BFormInput},
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            items: [],
            user: JSON.parse(localStorage.getItem('user')),
            student_number: {},
            student_: {name: "", number: ""},
            from: "",
            to: "",
            students: [],
            selectedStudent:  { name: "", number: "" },
            numbers: {},
            classnames: [],
            classrooms: [],
            classnames_: [],
            classrooms_: [],
            custom: "",
            custom2: "",
            types: [
                "عام",
                "إداري",
                "قانوني",
                "تقني",
                "ديني",
                "تعليمي",
                "بيني",
                "صحي",
                "سياحي",
                "إعلامي",
                "اجتماعي",
                "علمي",
                "رياضي",
                "ثقافي",
                "تسويقي",
                "مالي",
                "ترفيهي"
            ],
            type: "",
            details: "",
            hours: null,
            arr: []
        }
    },
    created(){
        if(!checkPer("students|hours")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.getStudents();
    },
    methods: {
        closeFilter(){
            $("#filter___BV_modal_header_ > button").click();
        },
        getItems(){
            var g = this;
            $.post(api + '/user/students/hours/list', {
                jwt: this.user.jwt,
                number: this.student_number.number,
                from: this.from,
                to: this.to
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.items = r.response
                    var arr = {};
                    g.items.forEach(function(a){
                        if(!arr[a.number]){
                            arr[a.number] = g.getStudent(a.number);
                            arr[a.number].arr = [a]
                        }else{
                            arr[a.number].arr.push(a)
                        }
                    })
                    g.arr = [];
                    g.students.forEach(function(a){
                        if(arr[a.number]){
                            g.arr.push(arr[a.number])
                        }else{
                            a.arr = []
                            g.arr.push(a)
                        }
                    })
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        getStudents(){
            var g = this;
            $.post(api + '/user/students/list', {
                jwt: this.user.jwt,
                q: this.q,
                page: 1,
                perpage: 10000
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.students = r.response
                    g.students.forEach(function(a){
                        g.numbers[a.number] = a;
                        if(!g.classnames_.includes(a.classname)){
                            g.classnames_.push(a.classname)
                            g.classnames.push({title: a.classname})
                        }
                        if(!g.classrooms_.includes(a.classname + ' - ' + a.classroom)){
                            g.classrooms_.push(a.classname + ' - ' + a.classroom)
                            g.classrooms.push({title: a.classname + ' - ' + a.classroom})
                        }
                    })
                    g.getItems()
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        getStudent(number){
            return this.numbers[number] ?? {};
        },
        addBreak(){
            var g = this;
            $.post(api + '/user/students/hours/add', {
                jwt: g.user.jwt,
                number: g.selectedStudent.number,
                type: g.type,
                details: g.details,
                hours: g.hours
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    $("#add___BV_modal_header_ > button").click();
                    g.getItems()
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        deleteItem(id){
            var g = this;
            if(confirm("متأكد من الحذف؟")){
                var g = this;
                $.post(api + '/user/students/hours/delete', {
                    jwt: g.user.jwt,
                    id: id
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response, 200)
                    }else{
                        g.getItems()
                    }
                }).fail(function(){
                    alert("حدث خطأ", 200)
                })
            }
        },
        customprint(type, ex){
            var g = this;
            var arr = [];
            g.arr.forEach(function(a){
                if(type == 'have'){
                    if(a.arr.length){
                        arr.push(a)
                    }
                }
                if(type == 'nothave'){
                    if(!a.arr.length){
                        arr.push(a)
                    }
                }
                if(type == 'classname'){
                    if(a.classname == ex){
                        arr.push(a)
                    }
                }
                if(type == 'classroom'){
                    if(a.classname + ' - ' + a.classroom == ex){
                        arr.push(a)
                    }
                }
                if(type == 'student'){
                    if(a.number == ex){
                        arr.push(a)
                    }
                }
            })
            g.print(arr);
        },
        print(arr){
            var tbody = '', index = 0, g = this;
            arr.forEach(function(a, i){
                index++
                tbody = tbody + `
                <tr ${a.arr.length > 0 ? 'style="background: #eee"' : ''}>
                    <td ${a.arr.length > 0 ? 'rowspan=' + a.arr.length : ''}>
                        ${i+1}
                    </td>
                    <td ${a.arr.length > 0 ? 'rowspan=' + a.arr.length : ''}>
                        ${a.name}
                    </td>
                    ${a.arr.length == 0 ? "<td>---</td><td>----</td><td>---</td>" : ""}
                    `;
                    a.arr.forEach(function(b, i){
                        if(i == 0){
                            tbody = tbody + `
                            <td style="background: #eee">${b.type}</td>
                            <td style="background: #eee">${b.hours}</td>
                            `
                        }
                    })
                    if(a.arr.length){
                        var total = 0;
                        a.arr.forEach(function(c){
                            total = total + c.hours;
                        })
                            tbody = tbody + `
                            <td rowspan="${a.arr.length}">
                                ${total}
                            </td>
                        `
                    }
                    tbody = tbody + `
                    </tr>
                    `
                    a.arr.forEach(function(b, i){
                        if(i != 0){
                            tbody = tbody + `
                            <tr>
                                <td style="background: #eee">${b.type}</td>
                                <td style="background: #eee">${b.hours}</td>
                            </tr>
                            `
                        }
                    })
            })
            var win = window.open("", "")
            win.document.write(`
            <html dir='rtl'>
                <head>
                    <style>
                        table, td, th {
                        border: 1px solid;
                        }
                        tr{
                            text-align: center
                        }
                        table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                </style>
                </head>
                <body>
                    <div class='container-fluid'>
                        <br>
                        <div class='row' style='width: 100%; padding: 0px; position: relative;'>
                            <div class='col-4 text-center' style='width: 30%; text-align: center; display: inline-block; position: relative; top: -10px'>
                            <h5>
                                المملكة العربية السعودية
                                <br><br>وزارة التعليم
                            </h5>
                            </div>
                            <div class='col-4 text-center' style='width: 30%; text-align: center; display: inline-block'>
                                <img style='width: 100px; position: relative; top: 20px' src='https://static.arrajol.com/users/user223141/%D8%B4%D8%B9%D8%A7%D8%B1-%D9%88%D8%B2%D8%A7%D8%B1%D8%A9-%D8%A7%D9%84%D8%AA%D8%B9%D9%84%D9%8A%D9%85-%D8%A7%D9%84%D8%AC%D8%AF%D9%8A%D8%AF-%D8%B4%D9%81%D8%A7%D9%81.png'>

                                <center><h2 class='text-danger'>
                                    تقرير ساعات التطوع
                                </h2></center>
                            </div>
                            <div class='col-4 text-center' style='width: 30%; text-align: center; display: inline-block; position: relative; top: -10px'>
                            <h5>
                                ${g.user.name}
                            </h5>
                            </div>
                            <div class='col-12'>
                                <table>
                                    <thead>
                                        <th>
                                            م
                                        </th>
                                        <th>
                                        اسم الطالب  
                                        </th>
                                        <th>
                                            المجال العام للتطوع
                                        </th>
                                        <th>
                                            عدد الساعات
                                        </th>
                                        <th>
                                            مجموع الساعات
                                        </th>
                                    </thead>
                                    <tbody>
                                        ${tbody}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
            `)
            win.document.close()
            setTimeout(() => {
                win.print()
            }, 1000);
        }
    }
}
</script>

<style>
@import url(https://cdnjs.cloudflare.com/ajax/libs/vue-select/3.10.0/vue-select.css);
#vs3__listbox{
    max-height: 150px !important;
    overflow: hidden !important;
}
</style>